import { Button, Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { CustomerDataResponse } from '../../api/customerApi';

type CustomerSummaryFooterProps = {
  customerData: CustomerDataResponse | undefined;
};

export const CustomerSummaryFooter: React.FC<CustomerSummaryFooterProps> = ({
  customerData,
}) => {
  const handleOpenLink = () => {
    window.open(customerData?.urlViewQueimaDiaria, '_blank');
  };

  return (
    <CustomFooter align='middle'>
      <Col>
        <Button type='primary' onClick={handleOpenLink}>
          Ver Queima Diária como Usuário
        </Button>
      </Col>
    </CustomFooter>
  );
};

const CustomFooter = styled(Row)`
  height: 64px;
  width: 100%;
  padding: 0 120px;
  line-height: 64px;
  background: #1f1f1f;

  border-top: 1px solid #424242;

  position: fixed;
  bottom: 0;
  left: 0;
`;
