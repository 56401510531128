import { CustomerDataResponse } from '../../api/customerApi';
import { CardsSection } from './CardsSection';
import { CustomerProfileSection } from './CustomerProfileSection';
import { CustomerSummaryFooter } from './CustomerSummaryFooter';

type CustomerSummaryTabProps = {
  customerData: CustomerDataResponse;
  setTabActive: (key: string) => void;
};

export const CustomerSummaryTab: React.FC<CustomerSummaryTabProps> = ({
  customerData,
  setTabActive,
}) => {
  return (
    <div>
      <CardsSection
        customerData={customerData}
        onClickClassOnMonth={() => setTabActive('0')}
        onClickPaymentsCard={() => setTabActive('0')}
      />
      <CustomerProfileSection customerData={customerData} />
      <CustomerSummaryFooter customerData={customerData} />
    </div>
  );
};
