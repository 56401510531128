import { Input, Tag } from 'antd';
import { useState } from 'react';

interface InputTagProps {
  defaultValue?: string[];
  placeHolder: string;
  onChange: (value: string[]) => void;
}
export const InputTag = ({
  defaultValue,
  placeHolder,
  onChange,
}: InputTagProps) => {
  const [inputValue, setInputValue] = useState('');
  const [values, setValues] = useState<Set<string>>(
    defaultValue ? new Set(defaultValue) : new Set()
  );
  const handleAddTag = (e: any) => {
    setValues(prevValues => {
      const currentValues = new Set(prevValues.add(e.target.value));
      onChange([...currentValues]);
      return currentValues;
    });
    setInputValue('');
  };
  const handleClearTag = (tagName: string) => {
    setValues(prevValues => {
      const valuesWithoutTagName = new Set(prevValues);
      valuesWithoutTagName.delete(tagName);
      onChange([...valuesWithoutTagName]);
      return valuesWithoutTagName;
    });
  };

  const handleClearLastTag = () => {
    if (values.size === 0) return;
    setValues(prevValues => {
      const valuesWithoutLastItem = new Set(prevValues);
      const lastItem = Array.from(prevValues).pop();
      if (lastItem) valuesWithoutLastItem.delete(lastItem);
      onChange([...valuesWithoutLastItem]);
      return valuesWithoutLastItem;
    });
  };

  return (
    <Input
      placeholder={placeHolder && values.size === 0 ? placeHolder : undefined}
      onPressEnter={handleAddTag}
      onKeyDown={e => {
        if (e.key === 'Backspace' && !!!inputValue) handleClearLastTag();
      }}
      value={inputValue}
      onChange={e => setInputValue(e.target.value)}
      prefix={
        <>
          {[...values].map(value => (
            <Tag
              closable
              onClose={e => {
                e.preventDefault();
                handleClearTag(value);
              }}
            >
              {value}
            </Tag>
          ))}
        </>
      }
    />
  );
};
