import { Controller, SwitchController, TextAreaController } from '@organisms';
import { Col, Divider, Form, Row, Typography } from 'antd';
import { Control, Controller as ControllerHookForm } from 'react-hook-form';

import { InputTag } from 'src/app/framework/atoms/InputTag/InputTag.atom';
import { UploadImage } from 'src/features/Funnel/Components/UploadImage';
import { Space } from 'src/features/roles/components/styles';

interface FormProductProps {
  control: Control<any>;
  isPhysicProduct: boolean;
  physicProductPrimaryColor: string;
}

export const FormProduct = ({
  control,
  isPhysicProduct,
  physicProductPrimaryColor,
}: FormProductProps) => {
  const { Text } = Typography;

  return (
    <Form
      layout='vertical'
      requiredMark='optional'
      style={{ width: '100%', paddingBlockEnd: '140px' }}
    >
      <Divider />
      <Row style={{ alignItems: 'center', paddingBlockEnd: '20px' }}>
        <Text
          style={{
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '28px',
            paddingInlineEnd: '16px',
          }}
        >
          Produto Físico
        </Text>
        <SwitchController
          control={control}
          defaultValue={false}
          name='isPhysicProduct'
          sideLabel
        />
      </Row>
      <Row style={{ display: 'flex', gap: '24px' }}>
        {isPhysicProduct && (
          <>
            <Row style={{ width: '100%', gap: '24px' }}>
              <Col style={{ flex: 1 }}>
                <Controller
                  control={control}
                  showCount={true}
                  name={`physicProduct.seo.title`}
                  defaultValue={''}
                  placeholder='Digite o título aqui'
                  autoComplete='off'
                  label='Título'
                  required
                />
              </Col>
              <Col>
                <Controller
                  prefix={
                    <Space
                      style={{
                        width: '20px',
                        height: '20px',
                        background: physicProductPrimaryColor,
                        borderRadius: '2px',
                        margin: '0px',
                        border: '1px solid #d3d3d3',
                      }}
                    />
                  }
                  control={control}
                  name={`physicProduct.primaryColor`}
                  label='Cor do Produto'
                  required={true}
                />
              </Col>
            </Row>
            <Row style={{ width: '100%', gap: '24px' }}>
              <Col style={{ flex: 1 }}>
                <TextAreaController
                  control={control}
                  name='physicProduct.seo.smallDescription'
                  placeholder='Área de texto'
                  autoComplete='off'
                  label='Descrição curta'
                  isMaxLength={true}
                  row={3}
                  required
                  maxLength={160}
                />
              </Col>
              <Col style={{ flex: 1 }}>
                <TextAreaController
                  control={control}
                  name='physicProduct.seo.largeDescription'
                  placeholder='Área de texto'
                  autoComplete='off'
                  label='Descrição longa'
                  isMaxLength={true}
                  row={3}
                  required
                  maxLength={190}
                />
              </Col>
            </Row>
            <Row style={{ width: '100%', gap: '24px' }}>
              <Col style={{ flex: 1 }}>
                <UploadImage
                  style={{ width: '100%' }}
                  tooltip={{
                    title: () => (
                      <Typography.Text>
                        Imagem do produto a ser exibida nas redes sociais
                        (google, ig, twitter, etc..)
                      </Typography.Text>
                    ),
                  }}
                  control={control}
                  name={`physicProduct.seo.image`}
                  label='Imagem'
                  required
                />
              </Col>
              <Col
                style={{
                  flex: 1,
                }}
              >
                <UploadImage
                  style={{ width: '100%' }}
                  tooltip={{
                    title: () => (
                      <Typography.Text>
                        Ícone da página de checkout do produto
                      </Typography.Text>
                    ),
                  }}
                  control={control}
                  name={`physicProduct.seo.favicon`}
                  label='Fav Icon'
                  required
                />
              </Col>
            </Row>
            <Col style={{ width: '100%' }}>
              <Controller
                control={control}
                showCount={true}
                name={`physicProduct.seo.siteName`}
                defaultValue={''}
                placeholder='Digite o nome aqui'
                autoComplete='off'
                label='Nome do site'
                required
              />
            </Col>
            <Col style={{ width: '100%' }}>
              <Controller
                control={control}
                showCount={true}
                name={`physicProduct.footerText`}
                defaultValue={''}
                placeholder='Digite o texto aqui'
                autoComplete='off'
                label='Texto do Rodapé'
                required
              />
            </Col>
            <Col style={{ width: '100%' }}>
              <Form.Item
                label='Palavras-chave'
                required
                tooltip={{
                  title: () => (
                    <Typography.Text>
                      Digite a palavra-chave e tecle enter para confirmar.
                    </Typography.Text>
                  ),
                }}
              >
                <ControllerHookForm
                  control={control}
                  name={`physicProduct.seo.keywords`}
                  render={({ field }) => {
                    return (
                      <InputTag
                        placeHolder='Insira as palavras-chave'
                        onChange={value => field.onChange(value)}
                        defaultValue={field.value}
                      />
                    );
                  }}
                />
              </Form.Item>
            </Col>
            <Row
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                gap: '24px',
              }}
            >
              <Col style={{ width: '177px' }}>
                <Controller
                  prefix='R$ '
                  control={control}
                  name={`displayPrice`}
                  defaultValue={''}
                  placeholder='0,00'
                  autoComplete='off'
                  label='Display price (moeda)'
                  required
                  type='number'
                />
              </Col>
              <Col style={{ width: '177px' }}>
                <Controller
                  prefix='R$ '
                  control={control}
                  name={`fullPrice`}
                  defaultValue={''}
                  placeholder='0,00'
                  autoComplete='off'
                  label='Full price (moeda)'
                  required
                  type='number'
                />
              </Col>
              <Col>
                <SwitchController
                  control={control}
                  defaultValue={false}
                  leftLabel='Frete grátis'
                  name='freeShip'
                  sideLabel
                />
              </Col>
            </Row>
          </>
        )}
        <Divider />
      </Row>
    </Form>
  );
};
